import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { axiosClient } from 'net/ajax'

async function getWebsites(scope) {
    const response = await axiosClient.post(
        '/ratesmgr/websites/get_websites_in_scope',
        { scope }
    )
    return response.data
}

function useGetWebsites() {
    const scope = useSelector(({ app }) => app.scope)
    const queryResult = useQuery('websites', () => getWebsites(scope))
    return {
        ...queryResult,
        websites: queryResult.data,
    }
}

export default function useWebsites() {
    const { isLoading: isFetchingWebsites, websites } = useGetWebsites()
    const [selectedWebsite, setSelectedWebsite] = useState(() => {
        if (websites?.length > 0) {
            return websites[0][0]
        }
    })

    useEffect(() => {
        if (websites?.length > 0) {
            setSelectedWebsite(websites[0][0])
        }
    }, [websites])

    const handleWebsiteChange = (e) => {
        setSelectedWebsite(e.target.value)
    }

    return {
        isFetchingWebsites,
        websites,
        selectedWebsite,
        handleWebsiteChange,
    }
}
