import React from 'react'
import { Box, Link } from 'styles'

export const OrderStorageAgreementContent = () => (
    <>
        <p>
            When you enable this functionality, ShipperHQ will collect and store
            data from the orders placed on your online store, including the
            name, address, email and phone number of the order recipient, the
            SKU and quantities for that order and the information on the
            shipping and packing information that ShipperHQ has calculated.
            ShipperHQ will display this information to you via your eCommerce
            platform or from within the ShipperHQ dashboard. It may also be
            processed and/or displayed by third parties that you have authorized
            to see it. The purpose of storing this information is so that you
            and third parties that you have authorized may use the information
            to automate your shipping and back office processes. All usage,
            storage, and transmission of this data is governed by the{' '}
            <Link href="/useragreement" target="_blank">
                User Agreement
            </Link>{' '}
            and{' '}
            <Link href="/privacy" target="_blank">
                Privacy Policy
            </Link>{' '}
            that you agreed to when you started using ShipperHQ.
        </p>
        <p>
            If you wish ShipperHQ to stop storing this information, in your
            ShipperHQ dashboard go to My Account &gt; Security &gt; Manage
            Permissions. If you wish ShipperHQ to delete the information we have
            collected, please email support@shipperhq.com.
        </p>
    </>
)

export default function OrderStorageAgreement(props) {
    return (
        <Box
            borderRadius="3px"
            mb={12}
            textAlign="left"
            p={'12, 12, 20, 12'}
            className="info-msg"
        >
            <Box className="small">
                <OrderStorageAgreementContent />
                <hr />
                {props.children}
            </Box>
        </Box>
    )
}
